import produce from 'immer';
import * as fromActions from '../actions/protocols.action';
import { DeleteProtocolDTO, DeleteProtocolRO, Protocol } from '../../../../models/protocol.interface';
import { Flowchart } from '../../../../models/flowchart.interface';

interface View {
    name: string;
    start?: boolean;
    paragraphId?: string;
    scrollPos?: {
        x: number;
        y: number;
    };
}

export interface ProtocolState {
    selected: Protocol;
    flowchart: Flowchart;
    scheme: string;
    currentVersion: any;
    draftVersion: any;
    deprecatedVersions: any[];
    storedDraftVersions: any[];
    loaded: boolean;
    loading: boolean;
    createProtocolLoading: boolean;
    createLocalProtocolLoading: boolean;
    deleteProtocol: {
        request: DeleteProtocolDTO;
        response: Partial<DeleteProtocolRO>;
        loading: boolean;
        loaded: boolean;
    };
    searchProtocol: {
        query: string;
        entries: Protocol[];
        loading: boolean;
        loaded: boolean;
    };
    copyProtocol: {
        loading: boolean;
    };
    currentView: string;
    views: View[];
    language: string;
    lastIntegrationSurveyDate: Date;
}

export const initialState: ProtocolState = {
    selected: null,
    flowchart: null,
    scheme: '',
    currentVersion: null,
    draftVersion: null,
    deprecatedVersions: [],
    storedDraftVersions: [],
    loaded: false,
    loading: false,
    createProtocolLoading: false,
    createLocalProtocolLoading: false,
    deleteProtocol: {
        request: {
            protocolId: null
        },
        response: {
            protocolId: null,
            message: null,
            url: null,
            error: null
        },
        loading: false,
        loaded: false
    },
    searchProtocol: {
        query: ',',
        entries: [],
        loading: false,
        loaded: false
    },
    copyProtocol: {
        loading: false
    },
    currentView: '',
    views: [],
    language: null,
    lastIntegrationSurveyDate: null
};

export function reducer(state = initialState, action: fromActions.ProtocolAction): ProtocolState {
    switch (action.type) {
        case fromActions.CREATE_PROTOCOL: {
            return { ...state, createProtocolLoading: true };
        }

        case fromActions.CREATE_PROTOCOL_SUCCESS: {
            return { ...state, createProtocolLoading: false };
        }

        case fromActions.CREATE_PROTOCOL_FAIL: {
            return { ...state, createProtocolLoading: false };
        }

        case fromActions.DELETE_PROTOCOL: {
            const {
                payload: { protocolId }
            } = action;
            return {
                ...initialState,
                deleteProtocol: {
                    ...initialState.deleteProtocol,
                    request: {
                        protocolId
                    },
                    loading: true
                }
            };
        }

        case fromActions.DELETE_PROTOCOL_SUCCESS: {
            const {
                payload: { message, url }
            } = action;
            return {
                ...state,
                deleteProtocol: {
                    ...state.deleteProtocol,
                    response: {
                        protocolId: state.deleteProtocol.request.protocolId,
                        message,
                        url,
                        error: null
                    },
                    loading: false,
                    loaded: true
                }
            };
        }

        case fromActions.DELETE_PROTOCOL_FAIL: {
            const {
                payload: { error }
            } = action;
            return {
                ...state,
                deleteProtocol: {
                    ...state.deleteProtocol,
                    response: {
                        protocolId: state.deleteProtocol.request.protocolId,
                        message: null,
                        url: null,
                        error
                    },
                    loading: false,
                    loaded: false
                }
            };
        }

        case fromActions.CREATE_LOCAL_PROTOCOL: {
            return { ...state, createProtocolLoading: true };
        }

        case fromActions.CREATE_LOCAL_PROTOCOL_SUCCESS: {
            return { ...state, createLocalProtocolLoading: false };
        }

        case fromActions.CREATE_LOCAL_PROTOCOL_FAIL: {
            return { ...state, createLocalProtocolLoading: false };
        }

        case fromActions.COPY_PROTOCOL: {
            return { ...state, copyProtocol: { ...state.copyProtocol, loading: true } };
        }

        case fromActions.COPY_PROTOCOL_SUCCESS: {
            return { ...state, copyProtocol: { ...state.copyProtocol, loading: false } };
        }

        case fromActions.COPY_PROTOCOL_FAIL: {
            return { ...state, copyProtocol: { ...state.copyProtocol, loading: false } };
        }

        case fromActions.GET_PROTOCOLS_BY_QUERY: {
            const {
                payload: { query }
            } = action;

            return { ...state, searchProtocol: { ...state.searchProtocol, query, loading: true, loaded: false } };
        }

        case fromActions.GET_PROTOCOLS_BY_QUERY_SUCCESS: {
            const {
                payload: { protocols }
            } = action;
            return {
                ...state,
                searchProtocol: { ...state.searchProtocol, entries: protocols, loading: false, loaded: true }
            };
        }

        case fromActions.GET_PROTOCOLS_BY_QUERY_FAIL: {
            return { ...state, searchProtocol: { ...state.searchProtocol, loading: false, loaded: false } };
        }

        case fromActions.LOAD_PROTOCOL: {
            return { ...state, views: [], loading: true };
        }

        case fromActions.LOAD_PROTOCOL_SUCCESS: {
            const { payload } = action;
            const currentVersion = payload.versions.find(ver1 => ver1.status === 'Current');
            const draftVersion = payload.versions.find(ver2 => (ver2.status === 'Beta' || ver2.status === 'Draft'));
            const deprecatedVersions = payload.versions.filter(ver3 => ver3.status === 'Deprecated');
            const storedDraftVersions = payload.versions.filter(ver3 => ver3.status === 'Stored Draft');
            const flowchart = payload.flowchart;
            const views = produce(payload.views, draft => {
                draft.forEach(view => {
                    view.paragraphId = null;
                    view.scrollPos = { x: 0, y: 0 };
                });
            });
            const scheme = payload.flowchart.scheme;

            return {
                ...state,
                loading: false,
                loaded: true,
                selected: payload,
                currentVersion,
                draftVersion,
                deprecatedVersions,
                storedDraftVersions,
                flowchart,
                scheme,
                views,
                language: payload.language,
                lastIntegrationSurveyDate: action.payload.lastIntegrationSurveyDate
            };
        }

        case fromActions.LOAD_PROTOCOL_FAIL: {
            return { ...state, loading: false, loaded: false };
        }

        case fromActions.UPDATE_CURRENT_VIEW: {
            const {
                payload: {
                    view,
                    paragraphId,
                    scrollPos: { x, y }
                }
            } = action;

            // Save scroll position of current view before switching to the new view.
            let views = state.views.map(v =>
                v.name === state.currentView
                    ? {
                          name: state.currentView,
                          paragraphId: null,
                          scrollPos: { x, y }
                      }
                    : v
            );

            // If the paragraph id is set for the destination view, then we use that instead of the saved
            // scroll position (which is ignored).
            if (view === 'text' && paragraphId) {
                views = views.map(v =>
                    v.name === 'text'
                        ? {
                              name: 'text',
                              paragraphId,
                              scrollPos: { x: 0, y: 0 }
                          }
                        : v
                );
            }

            return {
                ...state,
                currentView: view,
                views
            };
        }

        case fromActions.SUBMIT_FLOW_ACTION: {
            return { ...state, loading: true };
        }

        case fromActions.SUBMIT_FLOW_ACTION_SUCCESS: {
            return { ...state, loading: false };
        }

        case fromActions.SUBMIT_FLOW_ACTION_FAIL: {
            return { ...state, loading: false, loaded: false };
        }
        case fromActions.UPDATE_SETTING: {
            return { ...state, loading: true };
        }

        case fromActions.UPDATE_SETTING_SUCCESS: {
            return { ...state, loading: false, loaded: true };
        }

        case fromActions.UPDATE_SETTING_FAIL: {
            return { ...state, loading: false, loaded: false };
        }

        // store sync specific

        case fromActions.STORE_UPDATE_GRADE_ASSESSMENT: {
            const {
                payload: { ppdId, key, value }
            } = action;
            const paragraphIndex = state.selected.paragraphs.findIndex(par => par.id + '' === ppdId);
            const paragraphSelected = {
                ...state.selected.paragraphs[paragraphIndex],
                gradeAssessment: {
                    ...state.selected.paragraphs[paragraphIndex].gradeAssessment,
                    questions: {
                        ...state.selected.paragraphs[paragraphIndex].gradeAssessment.questions,
                        [key]: value + ''
                    }
                }
            };
            const paragraphs = Object.assign([...state.selected.paragraphs], { [paragraphIndex]: paragraphSelected });
            return {
                ...state,
                selected: {
                    ...state.selected,
                    paragraphs
                }
            };
        }
        case fromActions.STORE_ADD_PARAGRAPH: {
            const {
                payload: { parentId, paragraph }
            } = action;
            const { level } = paragraph;
            const parentIndex = state.selected.paragraphs.findIndex(par => par.id + '' === parentId);

            const parParent =
                level + '' === '1'
                    ? {}
                    : {
                          ...state.selected.paragraphs[parentIndex],
                          children: [...state.selected.paragraphs[parentIndex].children, paragraph]
                      };
            const paragraphs =
                level + '' === '1'
                    ? [...state.selected.paragraphs, paragraph]
                    : Object.assign([...state.selected.paragraphs], { [parentIndex]: parParent });

            return {
                ...state,
                selected: {
                    ...state.selected,
                    paragraphs
                }
            };
        }

        case fromActions.SET_GRADE_STATUS: {
            const {
                payload: {ppdId, status, parentId}
            } = action;

            let hasGrade = false
            if (status == 'hasGrade') {
                hasGrade = true 
            }

            const parentIndex =
                parentId !== null ? state.selected.paragraphs.findIndex(par => par.id + '' === parentId + '') : null;

            const paragraphIndex =
                parentId !== null
                    ? state.selected.paragraphs[parentIndex].children.findIndex(
                          par => par.id + '' === ppdId + ''
                      )
                    : state.selected.paragraphs.findIndex(par => par.id + '' === ppdId + '');

            const originalState = state.selected.paragraphs

            let newState = undefined
            if(parentId) {
                newState = produce(originalState, draft => {
                    draft[parentIndex].children[paragraphIndex].sourceInfo.hasGrade = hasGrade;
                });
            } else {
                newState = produce(originalState, draft => {
                    draft[paragraphIndex].sourceInfo.hasGrade = hasGrade;
                });
            }

            return {
                ...state,
                selected: {
                    ...state.selected,
                    paragraphs: newState
                }
            };
        }

        case fromActions.STORE_EVIDENCE: {
            const {
                payload: { ppdId, evidence, parentId = null}
            } = action;
            
            let sourceInfo = evidence.sourceInfo || {};
            let sourceTable = evidence.sourceTable || [];
            let referencedArticles = evidence.referencedArticles || [];
            let sources = evidence.sources || [];
            let allSourceTable = evidence.allSourceTable || []
            const parentIndex =
                parentId !== null ? state.selected.paragraphs.findIndex(par => par.id + '' === parentId + '') : null;

            const paragraphIndex =
                parentId !== null
                    ? state.selected.paragraphs[parentIndex].children.findIndex(
                          par => par.id + '' === ppdId + ''
                      )
                    : state.selected.paragraphs.findIndex(par => par.id + '' === ppdId + '');

            const originalState = state.selected.paragraphs

            let newState = undefined
            if(parentId) {
                newState = produce(originalState, draft => {
                    draft[parentIndex].children[paragraphIndex].sourceTable = sourceTable;
                    draft[parentIndex].children[paragraphIndex].sources = sources;
                    draft[parentIndex].children[paragraphIndex].sourceInfo = sourceInfo;
                });
            } else {
                newState = produce(originalState, draft => {
                    draft[paragraphIndex].sourceTable = sourceTable;
                    draft[paragraphIndex].allSourceTable = allSourceTable;
                    draft[paragraphIndex].sources = sources;
                    draft[paragraphIndex].sourceInfo = sourceInfo;
                    draft[paragraphIndex].referencedArticles = referencedArticles;
                });
            }


            return {
                ...state,
                selected: {
                    ...state.selected,
                    paragraphs: newState
                }
            };

        }


        case fromActions.STORE_UPDATE_PARAGRAPH: {
            const {
                payload: { paragraph, parentId = null }
            } = action;
            const parentIndex =
                parentId !== null ? state.selected.paragraphs.findIndex(par => par.id + '' === parentId + '') : null;

            const paragraphIndex =
                parentId !== null
                    ? state.selected.paragraphs[parentIndex].children.findIndex(
                          par => par.id + '' === paragraph.id + ''
                      )
                    : state.selected.paragraphs.findIndex(par => par.id + '' === paragraph.id + '');

            const paragraphs =
                parentId !== null
                    ? Object.assign([...state.selected.paragraphs], {
                          [parentIndex]: {
                              ...state.selected.paragraphs[parentIndex],
                              children: Object.assign([...state.selected.paragraphs[parentIndex].children], {
                                  [paragraphIndex]: paragraph
                              })
                          }
                      })
                    : Object.assign([...state.selected.paragraphs], { [paragraphIndex]: paragraph });

            return {
                ...state,
                selected: {
                    ...state.selected,
                    paragraphs
                }
            };
        }
        case fromActions.STORE_DELETE_PARAGRAPH: {
            const {
                payload: { ppdId, parentId = null }
            } = action;

            const paragraphs =
                parentId !== null
                    ? state.selected.paragraphs.map(par => {
                          if (par.id + '' !== parentId) {
                              return par;
                          }
                          return { ...par, children: par.children.filter(child => child.id + '' !== ppdId) };
                      })
                    : state.selected.paragraphs.filter(par => par.id + '' !== ppdId + '');

            return {
                ...state,
                selected: {
                    ...state.selected,
                    paragraphs
                }
            };
        }
    }
    return state;
}

export const getSelected = (state: ProtocolState) => state.selected;
export const getSelectedLoading = (state: ProtocolState) => state.loading;
export const getSelectedFlowchart = (state: ProtocolState) => state.flowchart;
export const getViews = (state: ProtocolState) => state.views;
export const getCurrentView = (state: ProtocolState) => state.currentView;
export const getCurrentViewScroll = (state: ProtocolState) => state.views.find(view => view.name === state.currentView);
export const getSelectedScheme = (state: ProtocolState) => state.scheme;
export const getSelectedLoaded = (state: ProtocolState) => state.loaded;
export const getCurrentVersion = (state: ProtocolState) => state.currentVersion;
export const getDraftVersion = (state: ProtocolState) => state.draftVersion;
export const getDeprecatedVersions = (state: ProtocolState) => state.deprecatedVersions;
export const getStoredDraftVersions = (state: ProtocolState) => state.storedDraftVersions;
export const getCreateProtocolLoading = (state: ProtocolState) => state.createProtocolLoading;
export const getCreateLocalProtocolLoading = (state: ProtocolState) => state.createLocalProtocolLoading;
export const getSearchProtocolQuery = (state: ProtocolState) => state.searchProtocol.query;
export const getSearchProtocolEntries = (state: ProtocolState) => state.searchProtocol.entries;
export const getSearchProtocolLoading = (state: ProtocolState) => state.searchProtocol.loading;

export const getDeleteProtocolResponse = (state: ProtocolState) => state.deleteProtocol.response;
export const getDeleteProtocolLoading = (state: ProtocolState) => state.deleteProtocol.loading;
export const getDeleteProtocolLoaded = (state: ProtocolState) => state.deleteProtocol.loaded;

export const getLoadProtocolLanguage = (state: ProtocolState) => state.language;

export const getLastIntegrationSurveyDate = (state: ProtocolState) => state.lastIntegrationSurveyDate;