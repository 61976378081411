import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProtocolsService } from 'apps/web/src/app/services';
import * as fromStore from './../../../store'
import { Store } from '@ngrx/store';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'alii-survey-continuation-choice-modal',
    templateUrl: './survey-continuation-choice-modal.component.html',
    styleUrls: ['./survey-continuation-choice-modal.component.scss']
})
export class SurveyContinuationChoiceModalComponent implements OnInit {
    @Input()
    lastIntegrationSurveyDate: Date;

    @Input()
    documentId: string;

    @Input()
    populationId: string;

    @Input()
    versionId: string;

    @Input()
    version: string;

    constructor(
        public activeModal: NgbActiveModal,
        private protocolsService: ProtocolsService,
        private store: Store<fromStore.ProtocolsFeatureState>
    ) {}

    ngOnInit() {}

    continueSurvey() {
        this.activeModal.close();
    }

    copySurvey() {
        this.protocolsService.copySurvey(this.documentId, this.populationId).subscribe(() => {
          this.activeModal.close();
        });
      }

      startOver() {
        this.protocolsService.createNewSurvey(this.documentId, this.populationId).subscribe(() => {
          this.activeModal.close();
          this.store.dispatch(
            new fromStore.LoadProtocol({
              protocolId: this.documentId,
              versionId: this.versionId,
              version: this.version,
              populationId: this.populationId,
              showAllOutcomes: false
            })
          );
        });
    }
}