<div class="modal-header">
    <h4 class="modal-title">Bestaande vragenlijst</h4>
    <button type="button" class="close" aria-label="Close" (click)="continueSurvey()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p>Er is al een vragenlijst ingevuld op {{ lastIntegrationSurveyDate | date:'dd-MM-yyyy' }}</p>
    <a type="submit" style="width:100%; margin-bottom: 1rem;" class="btn btn-info" (click)="continueSurvey()">Ga verder met huidige vragenlijst</a>
    <a type="submit" style="width:100%; margin-bottom: 1rem;" class="btn btn-info" (click)="copySurvey()">Maak een nieuwe vragenlijst aan met de oude data</a>
    <a type="submit" style="width:100%;" class="btn btn-info" (click)="startOver()">Begin een compleet nieuwe vragenlijst</a>
</div>
