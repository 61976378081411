<ng-container *ngIf="!(protocol$ | async)?.edit && (protocol$ | async)?.is_pdf && !isPrint">
    <alii-web-protocol-pdf
        (eventBus)="handleEventBus($event)"
        [pane]="pane"
        [loading]="loading$ | async"
        [protocol]="protocol$ | async"
        [users]="users$ | async"
        [isEditAble]="true"
        [versionId]="versionId"
        [version]="version"
        [currentVersion]="currentVersion$ | async"
        [draftVersion]="draftVersion$ | async"
        [deprecatedVersions]="deprecatedVersions$ | async"
        [storedDraftVersions]="storedDraftVersions$ | async"
    ></alii-web-protocol-pdf>
</ng-container>
<ng-container *ngIf="!(protocol$ | async)?.edit && !(protocol$ | async)?.is_pdf && !isPrint">
    <alii-web-protocol-detail
        *ngIf="(protocol$ | async)"
        (eventBus)="handleEventBus($event)"
        [channelMessages]="channelMessages$ | async"
        [pane]="pane"
        [loading]="loading$ | async"
        [loadingModel]="loadingModel$ | async"
        [protocol]="protocol$ | async"
        [paragraphSelectedId]="paragraphSelectedId"
        [isEditAble]="false"
        [literatures]="literatures$ | async"
        [modelMessages]="messages"
        [versionId]="versionId"
        [version]="version"
        [users]="users$ | async"
        [currentVersion]="currentVersion$ | async"
        [draftVersion]="draftVersion$ | async"
        [deprecatedVersions]="deprecatedVersions$ | async"
        [storedDraftVersions]="storedDraftVersions$ | async"
        [gradeAssessment]="gradeAssessment$ | async"
        [paragraphsFiles]="paragraphsFiles$ | async"
        [modelFindings]="modelFindings$ | async"
        [modelOutcomes]="modelOutcomes$ | async"
        [modelTagList]="modelTagList$ | async"
        [protocolArticle]="protocolArticle$ | async"
        [viewByPopulation]="viewByPopulation"
        [populations]="populations$ | async"
        [views]="availableViews$ | async"
        [currentView]="currentView$ | async"
        [populationId]="populationId$ | async"
        [lastIntegrationSurveyDate]="lastIntegrationSurveyDate$ | async"
    ></alii-web-protocol-detail>
</ng-container>

<ng-container *ngIf="(protocol$ | async)?.edit && !(protocol$ | async)?.is_pdf && !isPrint">
    <alii-web-protocol-edit
        *ngIf="(protocol$ | async)"
        (eventBus)="handleEventBus($event)"
        [channelMessages]="channelMessages$ | async"
        [pane]="pane"
        [loading]="loading$ | async"
        [protocol]="protocol$ | async"
        [paragraphSelectedId]="paragraphSelectedId"
        [isEditAble]="true"
        [literatures]="literatures$ | async"
        [modelMessages]="messages"
        [versionId]="versionId"
        [version]="version"
        [users]="users$ | async"
        [keyQuestionImportList]="keyQuestionImportList$ | async"
        [currentVersion]="currentVersion$ | async"
        [draftVersion]="draftVersion$ | async"
        [deprecatedVersions]="deprecatedVersions$ | async"
        [storedDraftVersions]="storedDraftVersions$ | async"
        [gradeAssessment]="gradeAssessment$ | async"
        [paragraphsFiles]="paragraphsFiles$ | async"
        [modelFindings]="modelFindings$ | async"
        [modelOutcomes]="modelOutcomes$ | async"
        [modelTagList]="modelTagList$ | async"
        [pubmeds]="pubmeds$ | async"
        [protocolArticle]="protocolArticle$ | async"
        [viewByPopulation]="viewByPopulation"
        [populations]="populations$ | async"
        [views]="availableViews$ | async"
        [currentView]="currentView$ | async"
        [populationId]="populationId$ | async"
        [modelUpdateBySheetId]="modelUpdateBySheetId$ | async"
        [linkToProtocolId]="linkToProtolId$ | async"
    ></alii-web-protocol-edit>
</ng-container>

<ng-container *ngIf="isPrint">
    <protocol-detail-print
        *ngIf="(protocol$ | async)"
        (eventBus)="handleEventBus($event)"
        [content]="protocol$ | async"
        [isChartView]="!!(protocol$ | async)?.flowchart?.scheme"
        [isTextView]="!!(protocol$ | async)?.flowchart?.scheme"
        [versionId]="versionId"
        [version]="version"
    ></protocol-detail-print>
</ng-container>
