import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Category } from '../models/category.interface';
import { DeleteProtocolDTO, DeleteProtocolRO, Protocol } from '../models/protocol.interface';

// Development only
export type ProtocolsRequestAction = 'getProtocol';
export type ProtocolsRequestState = 'loading' | 'success' | 'fail';

export interface ProtocolRequest {
    action: ProtocolsRequestAction;
    state: ProtocolsRequestState;
    response?: any;
    error?: any;
}

const cn = 'ProtocolsService';

@Injectable()
export class ProtocolsService {
    // Development only
    private development: boolean;
    private _request$ = new BehaviorSubject<ProtocolRequest>(null);
    request$ = this._request$.asObservable();

    constructor(private http: HttpClient) {
        this.development = !environment.production;
    }

    getProtocolWithAllOutcomes(payload): Observable<any> {
        const { protocolId } = payload;

        const url = `${environment.baseUrl}/api/protocol/getProtocolContent.vm?protocolId=${protocolId}&showAllOutcomes=yes&app=web`;

        return this.http.get<any>(url).pipe(
            map(protocol => ({ protocol })),
            catchError((error: any) => throwError(error))
        );
    }

    getAll(): Observable<Protocol[]> {
        const url = `${environment.baseUrl}/api/protocol/getAllProtocols.vm?app=web`;
        return this.http.get<Protocol[]>(url).pipe(catchError((error: any) => throwError(error)));
    }

    getAllByCategory(): Observable<Category[]> {
        return this.http
            .get<Category[]>(`${environment.baseUrl}/api/protocol/getAllProtocolsList.vm`)
            .pipe(catchError((error: any) => throwError(error)));
    }

    getProtocolsByQuery(q: string): Observable<any[]> {
        return this.http.get<any[]>(`${environment.baseUrl}/api/protocol/search.vm?q=${q}`).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    getProtocol(payload): Observable<Protocol> {
        const { protocolId, versionId, version, populationId, viewByPopulation, showAllOutcomes, compareVersion } = payload;
        const queryVersionId = versionId ? `&versionId=${versionId}` : '';

        const queryVersion = version
            ? version === 'Current' || version === 'archived'
                ? ''
                : `&version=${version}`
            : '';
        const queryPopulationId = populationId ? `&populationId=${populationId}` : '';
        const _showAllOutcomes = viewByPopulation || showAllOutcomes ? `&showAllOutcomes=true` : '';
        const _compareVersion = compareVersion ? `&compareVersion=true` : '';

        const url = `${environment.baseUrl}/api/protocol/getProtocolContent.vm?protocolId=${protocolId}${queryVersionId}${queryVersion}${queryPopulationId}${_showAllOutcomes}${_compareVersion}&app=web`;

        this._notify('getProtocol', 'loading');
        return this.http.get<Protocol>(url).pipe(
            map(response => {
                if (this.development) {
                    // response.paragraphs.forEach((paragraph, i) => {
                    //     const paragraphs = paragraph.children;
                    //     if (paragraphs) {
                    //         paragraphs.forEach((p, j) =>
                    //             console.log(
                    //                 `${JSON.stringify(
                    //                     p.questions.map(q => q.hide)
                    //                 )} ${cn} getProtocol() ${j} paragraph ${i}`
                    //             )
                    //         );
                    //     }
                    // });
                    this._notify('getProtocol', 'success', response);
                }
                return response;
            }),
            catchError((error: any) => {
                this._notify('getProtocol', 'fail', error);
                return throwError(error);
            })
        );
    }

    setProtocol(protocolId: string, options: any): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/updateProtocolSettings.vm`;
        const data = {
            ...options,
            protocolId
        };
        return this.http.post<any>(url, data).pipe(catchError((error: any) => throwError(error)));
    }

    createProtocol(payload): Observable<any> {
        const { title, category, html } = payload;
        const url = `${environment.baseUrl}/api/protocol/createNewProtocol.vm`;
        const data = {
            title,
            category,
            html
        };
        return this.http.post<any>(url, data).pipe(catchError((error: any) => throwError(error)));
    }

    deleteProtocol(payload: DeleteProtocolDTO): Observable<DeleteProtocolRO> {
        const { protocolId } = payload;
        const endpointUrl = `${environment.baseUrl}/api/protocol/updateProtocol.vm`;
        const body = {
            protocolId,
            action: 'delete'
        };

        return this.http.post<DeleteProtocolRO>(endpointUrl, body).pipe(
            map(response => response),
            catchError((error: any) => throwError(error))
        );
    }

    createLocalProtocol(payload): Observable<any> {
        const { parentId } = payload;
        const url = `${environment.baseUrl}/api/protocol/createNewProtocol.vm`;
        const data = {
            parentId,
            replace: true
        };
        return this.http.post<any>(url, data).pipe(catchError((error: any) => throwError(error)));
    }

    copyProtocol(payload): Observable<any> {
        const { parentId } = payload;
        const url = `${environment.baseUrl}/api/protocol/createNewProtocol.vm`;
        const data = {
            parentId
        };
        return this.http.post<any>(url, data).pipe(catchError((error: any) => throwError(error)));
    }

    submitFlowAction(action): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/updateProtocol.vm`;
        return this.http.post<any>(url, action).pipe(catchError((error: any) => throwError(error)));
    }

    submitUserFeedback(feedback: string): Observable<any> {
        const url = `${environment.baseUrl}/api/protocol/submitFeedback.vm`;
        return this.http
            .post<any>(url, { text: feedback })
            .pipe(catchError((error: any) => throwError(error)));
    }

    copySurvey(documentId: string, populationId: string): Observable<any> {
        const url = `${environment.baseUrl}/api/surveycontinuation/copy`;
        return this.http
            .post<any>(url, { documentId, populationId })
            .pipe(catchError((error: any) => throwError(error)));
    }

    createNewSurvey(documentId: string, populationId: string): Observable<any> {
        const url = `${environment.baseUrl}/api/surveycontinuation/new`;
        return this.http
            .post<any>(url, { documentId, populationId })
            .pipe(catchError((error: any) => throwError(error)));
    }

    // Development only
    private _notify(action: ProtocolsRequestAction, state: ProtocolsRequestState, respOrErr: any = null) {
        if (this.development) {
            if (state === 'success') {
                this._request$.next({ action, state, response: respOrErr });
            } else if (state === 'fail') {
                this._request$.next({ action, state, error: respOrErr });
            } else {
                this._request$.next({ action, state });
            }
        }
    }
}
